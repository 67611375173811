import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const discounts = createSlice({
	name: 'discounts',
	initialState: {
		loaded: false,
		loading: false,
		count: 0,
		total: 0,
		discounts: [],
		maxed: false,
	},
	reducers: {
		DISCOUNTS_LOADING(state, action) {
			return (state = { ...state, loading: true, loaded: false });
		},
		DISCOUNTS_SUCCESS(state, action) {
			const { discounts, maxed, count, loaded, total } = action.payload;
			return (state = {
				loading: false,
				discounts,
				total,
				maxed,
				count,
				loaded: true,
			});
		},
		DISCOUNTS_ERROR(state, action) {
			return (state = { ...state, loading: false, loaded: true });
		},
	},
});

export const DISCOUNTS_LOADING = discounts.actions.DISCOUNTS_LOADING;
export const DISCOUNTS_SUCCESS = discounts.actions.DISCOUNTS_SUCCESS;
export const DISCOUNTS_ERROR = discounts.actions.DISCOUNTS_ERROR;

export const GET_DISCOUNTS = (props) => async (dispatch, getState) => {
	const { discounts } = await getState();

	if (discounts.loading) {
		return;
	}

	dispatch(DISCOUNTS_LOADING());

	await axios
		.post(`${process.env.REACT_APP_SERVER_API}/api/admin/discounts`, {
			count: props === true ? 0 : discounts.count,
		})
		.then(async (res) => {
			console.log(res.data.discounts);

			let discounts2 = [
				...new Set(
					props === true
						? [...res.data.discounts]
						: [...res.data.discounts, ...discounts.discounts]
				),
			];
			let count = discounts2.length;
			let maxed = count >= res.data.discountsCount ? true : false;
			let total = res.data.discountsCount;

			dispatch(
				DISCOUNTS_SUCCESS({
					discounts: discounts2.sort((a, b) => (a.date < b.date ? 1 : -1)),
					maxed,
					count,
					total,
				})
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(DISCOUNTS_ERROR());
		});
};

export default discounts;
