import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const stores = createSlice({
	name: 'stores',
	initialState: {
		loaded: false,
		loading: false,
		count: 0,
		total: 0,
		stores: [],
		maxed: false,
	},
	reducers: {
		STORES_LOADING(state, action) {
			return (state = { ...state, loading: true, loaded: false });
		},
		STORES_SUCCESS(state, action) {
			const { stores, maxed, count, total } = action.payload;
			return (state = {
				loading: false,
				stores,
				total,
				maxed,
				count,
				loaded: true,
			});
		},
		STORES_ERROR(state, action) {
			return (state = { ...state, loading: false, loaded: true });
		},
	},
});

export const STORES_LOADING = stores.actions.STORES_LOADING;
export const STORES_SUCCESS = stores.actions.STORES_SUCCESS;
export const STORES_ERROR = stores.actions.STORES_ERROR;

export const GET_STORES = (props) => async (dispatch, getState) => {
	const { stores } = await getState();

	if (stores.loading) {
		return;
	}

	dispatch(STORES_LOADING());

	await axios
		.post(`${process.env.REACT_APP_SERVER_API}/api/admin/stores`, {
			count: stores.count,
		})
		.then(async (res) => {
			let stores2 = [...new Set([...stores.stores, ...res.data.stores])];
			let count = stores2.length;
			let maxed = count >= res.data.storesCount ? true : false;
			let total = res.data.storesCount;

			dispatch(STORES_SUCCESS({ stores: stores2, maxed, count, total }));
		})
		.catch((err) => {
			console.log(err);
			dispatch(STORES_ERROR());
		});
};

export default stores;
