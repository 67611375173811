import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

const orders = createSlice({
	name: 'orders',
	initialState: {
		loaded: false,
		loading: false,
		count: 0,
		total: 0,
		orders: [],
		maxed: false,
	},
	reducers: {
		ORDERS_LOADING(state, action) {
			return (state = { ...state, loading: true, loaded: false });
		},
		ORDERS_SUCCESS(state, action) {
			const { orders, maxed, count, loaded, total } = action.payload;
			return (state = {
				loading: false,
				orders,
				total,
				maxed,
				count,
				loaded: true,
			});
		},
		ORDERS_ERROR(state, action) {
			return (state = { ...state, loading: false, loaded: true });
		},
	},
});

export const ORDERS_LOADING = orders.actions.ORDERS_LOADING;
export const ORDERS_SUCCESS = orders.actions.ORDERS_SUCCESS;
export const ORDERS_ERROR = orders.actions.ORDERS_ERROR;

export const GET_ORDERS = (props) => async (dispatch, getState) => {
	const { orders } = await getState();

	if (orders.loading) {
		return;
	}

	dispatch(ORDERS_LOADING());

	await axios
		.post(`${process.env.REACT_APP_SERVER_API}/api/admin/orders`, {
			count: props === true ? 0 : orders.count,
		})
		.then(async (res) => {
			let orders2 =
				props === true
					? [...res.data.orders]
					: [...res.data.orders, ...orders.orders];
			let count = orders2.length;
			let maxed = count >= res.data.ordersCount ? true : false;
			let total = res.data.ordersCount;

			console.log(orders2);

			dispatch(
				ORDERS_SUCCESS({
					orders: orders2.sort((a, b) => (a.date < b.date ? 1 : -1)),
					maxed,
					count,
					total,
				})
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(ORDERS_ERROR());
		});
};

export default orders;
