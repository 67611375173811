import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

const listings = createSlice({
	name: 'listings',
	initialState: {
		loaded: false,
		loading: false,
		count: 0,
		total: 0,
		listings: [],
		maxed: false,
	},
	reducers: {
		LISTINGS_LOADING(state, action) {
			return (state = { ...state, loading: true, loaded: false });
		},
		LISTINGS_SUCCESS(state, action) {
			const { listings, maxed, count, loaded, total } = action.payload;
			return (state = {
				loading: false,
				listings,
				total,
				maxed,
				count,
				loaded: true,
			});
		},
		LISTINGS_ERROR(state, action) {
			return (state = { ...state, loading: false, loaded: true });
		},
	},
});

export const LISTINGS_LOADING = listings.actions.LISTINGS_LOADING;
export const LISTINGS_SUCCESS = listings.actions.LISTINGS_SUCCESS;
export const LISTINGS_ERROR = listings.actions.LISTINGS_ERROR;

export const GET_LISTINGS = (props) => async (dispatch, getState) => {
	const { listings } = await getState();

	if (listings.loading) {
		return;
	}

	dispatch(LISTINGS_LOADING());

	await axios
		.post(`${process.env.REACT_APP_SERVER_API}/api/admin/listings`, {
			count: props === true ? 0 : listings.count,
		})
		.then(async (res) => {
			console.log(res.data.listings);

			let listings2 = [
				...new Set(
					props === true
						? [...res.data.listings]
						: [...res.data.listings, ...listings.listings]
				),
			];
			let count = listings2.length;
			let maxed = count >= res.data.listingsCount ? true : false;
			let total = res.data.listingsCount;

			dispatch(
				LISTINGS_SUCCESS({
					listings: listings2.sort((a, b) => (a.date < b.date ? 1 : -1)),
					maxed,
					count,
					total,
				})
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(LISTINGS_ERROR());
		});
};

export default listings;
