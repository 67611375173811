import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

const users = createSlice({
	name: 'users',
	initialState: {
		loaded: false,
		loading: false,
		count: 0,
		total: 0,
		users: [],
		maxed: false,
	},
	reducers: {
		USERS_LOADING(state, action) {
			return (state = { ...state, loading: true, loaded: true });
		},
		USERS_SUCCESS(state, action) {
			const { users, maxed, count, loaded, total } = action.payload;
			return (state = {
				loading: false,
				users,
				total,
				maxed,
				count,
				loaded: true,
			});
		},
		USERS_ERROR(state, action) {
			return (state = { ...state, loading: false, loaded: false });
		},
	},
});

export const USERS_LOADING = users.actions.USERS_LOADING;
export const USERS_SUCCESS = users.actions.USERS_SUCCESS;
export const USERS_ERROR = users.actions.USERS_ERROR;

export const GET_USERS = (props) => async (dispatch, getState) => {
	const { users } = await getState();

	if (users.loading) {
		return;
	}

	dispatch(USERS_LOADING());

	await axios
		.post(`${process.env.REACT_APP_SERVER_API}/api/admin/users`, {
			count: users.count,
		})
		.then(async (res) => {
			let users2 = [...new Set([...users.users, ...res.data.users])];
			let count = users2.length;
			let maxed = count >= res.data.usersCount ? true : false;
			let total = res.data.usersCount;

			console.log(users2);

			dispatch(USERS_SUCCESS({ users: users2, maxed, count, total }));
		})
		.catch((err) => {
			console.log(err);
			dispatch(USERS_ERROR());
		});
};

export default users;
