import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const consignments = createSlice({
	name: 'consignments',
	initialState: {
		loaded: false,
		loading: false,
		count: 0,
		total: 0,
		consignments: [],
		maxed: false,
	},
	reducers: {
		CONSIGNMENTS_LOADING(state, action) {
			return (state = { ...state, loading: true, loaded: false });
		},
		CONSIGNMENTS_SUCCESS(state, action) {
			const { consignments, maxed, count, loaded, total } = action.payload;
			return (state = {
				loading: false,
				consignments,
				total,
				maxed,
				count,
				loaded: true,
			});
		},
		CONSIGNMENTS_ERROR(state, action) {
			return (state = { ...state, loading: false, loaded: true });
		},
	},
});

export const CONSIGNMENTS_LOADING = consignments.actions.CONSIGNMENTS_LOADING;
export const CONSIGNMENTS_SUCCESS = consignments.actions.CONSIGNMENTS_SUCCESS;
export const CONSIGNMENTS_ERROR = consignments.actions.CONSIGNMENTS_ERROR;

export const GET_CONSIGNMENTS = (props) => async (dispatch, getState) => {
	const { consignments } = await getState();

	if (consignments.loading) {
		return;
	}

	dispatch(CONSIGNMENTS_LOADING());

	await axios
		.post(`${process.env.REACT_APP_SERVER_API}/api/admin/consignments`, {
			count: props === true ? 0 : consignments.count,
		})
		.then(async (res) => {
			console.log(res.data.consignments);

			let consignments2 = [
				...new Set(
					props === true
						? [...res.data.consignments]
						: [...res.data.consignments, ...consignments.consignments]
				),
			];
			let count = consignments2.length;
			let maxed = count >= res.data.consignmentsCount ? true : false;
			let total = res.data.consignmentsCount;

			dispatch(
				CONSIGNMENTS_SUCCESS({
					consignments: consignments2.sort((a, b) =>
						a.date < b.date ? 1 : -1
					),
					maxed,
					count,
					total,
				})
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(CONSIGNMENTS_ERROR());
		});
};

export default consignments;
